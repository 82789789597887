<template>
  <div>
    <div class="enterprise">
      <div class="enterpriseContainer">
      
        <div class="traceInfoContain">
            <div
              style="
                margin-top: 10px;
                text-align: -webkit-center;
              "
            >
              <div class="homeProductTraceIcon" />
              <div
                style="
                  height: calc(100% - 5px - 6px);
                  width: 0px;
                  border-left: #ad412f 3px dotted;
                  margin-top: 4px;
                "
              />
            </div>
            <div class="fromenterprise">
              <div class="baseinfo">出品企业</div>
              <div class="name">{{ supplierName }}</div>
              <div class="businessTagContainer">
                <div class="businessTag" v-if="supplierAuth1">
                  <img
                    style="width: 19px; height: 19px; display: block"
                    src="../../assets/images/project1/businessTag.png"
                    alt=""
                  />
                  <div
                    style="
                      background-color: #f6e2c8;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      font-size: 9px;
                      color: #ac402f;
                      padding: 0px 4px;
                      display: flex;
                      align-items: center;
                      line-height: 9px;
                    "
                  >
                    {{ supplierAuth1 }}
                  </div>
                </div>
                <div class="businessTag" v-if="supplierAuth2">
                  <img
                    style="width: 19px; height: 19px; display: block"
                    src="../../assets/images/project1/businessTag.png"
                    alt=""
                  />
                  <div
                    style="
                      background-color: #f6e2c8;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      font-size: 9px;
                      color: #ac402f;
                      padding: 0px 4px;
                      display: flex;
                      align-items: center;
                      line-height: 9px;
                    "
                  >
                    {{ supplierAuth2 }}
                  </div>
                </div>
                <div class="businessTag" v-if="supplierAuth3">
                  <img
                    style="width: 19px; height: 19px; display: block"
                    src="../../assets/images/project1/businessTag.png"
                    alt=""
                  />
                  <div
                    style="
                      background-color: #f6e2c8;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      font-size: 9px;
                      color: #ac402f;
                      padding: 0px 4px;
                      display: flex;
                      align-items: center;
                      line-height: 9px;
                    "
                  >
                    {{ supplierAuth3 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
            <!-- 育苗阶段 -->
            <div class="traceInfoContain">
            <div
              style="
                margin-top: 12px;
                text-align: -webkit-center;
              "
            >
              <div style="margin-bottom: 4px" />
              <div class="homeProductTraceIcon" />
              <div
                style="
                  height: calc(100% - 5px - 6px);
                  width: 0;
                  border-left: #ad412f 3px dotted;
                  margin-top: 4px;
                "
              
              />
            </div>
            <div class="fromenterprise2">
              <div class="baseinfo">育苗阶段</div>
              <div class="infoleft">
                <div class="infoitem">
                  <div class="key">养殖天数</div>
                  <div class="value">{{ stageList[0].cycleDay }}</div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    养
                    <div style="width: 6.5px" />
                    殖
                    <div style="width: 7px" />
                    户
                  </div>

                  <div class="value">
                    {{
                      stageList[0].breedingFarmer
                        ? stageList[0].breedingFarmer
                        : "个体户"
                    }}
                  </div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    养
                    <div style="width: 6.5px" />
                    殖
                    <div style="width: 7px" />
                    场
                  </div>
                  <div class="value" style="color: #a50000">
                    {{
                      stageList[0].farmManagerName
                        ? stageList[0].farmManagerName
                        : "个体户"
                    }}
                  </div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    圈
                    <div style="width: 6.5px" />
                    舍
                    <div style="width: 7px" />
                    码
                  </div>
                  <div class="value">
                    {{
                      stageList[0].farmManagerCircleHouse
                        ? stageList[0].farmManagerCircleHouse
                        : "个体户"
                    }}
                  </div>
                </div>

                <div class="infoitem" v-if="stageList[0].feedingName">
                  <div class="key">喂食饲料</div>
                  <div class="value">{{ stageList[0].feedingName }}</div>
                </div>
                <div class="infoitem" v-if="stageList[0].vaccineName">
                  <div class="key">疫苗接种</div>
                  <div class="value">{{ stageList[0].vaccineName }}</div>
                </div>
                <div class="infoitem" v-if="stageList[0].pushTime">
                  <div class="key">入栏日期</div>
                  <div class="value">{{ stageList[0].formatpushTime }}</div>
                </div>
                <div class="infoitem" v-if="stageList[0].popTime">
                  <div class="key">出栏日期</div>
                  <div class="value">{{ stageList[0].formatpopTime }}</div>
                </div>
              </div>
              <div class="inforight">
                <img
                  v-if="stageList[0].thumbnailImageUrl"
                  style="width: 125px; height: 161px"
                  :src="stageList[0].thumbnailImageUrl"
                  alt=""
                />
                <img
                  v-if="!stageList[0].thumbnailImageUrl"
                  style="width: 125px; height: 161px"
                  src="https://asset.fsytss.com/trace/wcj/default_state_1.png"
                  alt=""
                />

                <div class="status" @click="showDialog(stageList[0].id)">
                  饲养情况
                </div>
              </div>
            </div>
          </div>

          <!-- 坡养阶段 -->
          <div class="traceInfoContain">
            <div
              style="
                margin-top: 12px;
                text-align: -webkit-center;
              "
            >
              <div style="margin-bottom: 4px" />
              <div class="homeProductTraceIcon" />
              <div
                style="
                  height: calc(100% - 5px - 6px);
                  width: 0;
                  border-left: #ad412f 3px dotted;
                  margin-top: 4px;
                "
               
              />
            </div>
            <div class="fromenterprise2">
              <div class="baseinfo">坡养阶段</div>
              <div class="infoleft">
                <div class="infoitem">
                  <div class="key">养殖天数</div>
                  <div class="value">{{ stageList[1].cycleDay }}</div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    养
                    <div style="width: 6.5px" />
                    殖
                    <div style="width: 7px" />
                    户
                  </div>
                  <div class="value">
                    {{
                      stageList[1].breedingFarmer
                        ? stageList[1].breedingFarmer
                        : "个体户"
                    }}
                  </div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    养
                    <div style="width: 6.5px" />
                    殖
                    <div style="width: 7px" />
                    场
                  </div>
                  <div class="value" style="color: #a50000">
                    {{
                      stageList[1].farmManagerName
                        ? stageList[1].farmManagerName
                        : "个体户"
                    }}
                  </div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    圈
                    <div style="width: 6.5px" />
                    舍
                    <div style="width: 7px" />
                    码
                  </div>
                  <div class="value">
                    {{
                      stageList[1].farmManagerCircleHouse
                        ? stageList[1].farmManagerCircleHouse
                        : "个体户"
                    }}
                  </div>
                </div>

                <div class="infoitem" v-if="stageList[1].feedingName">
                  <div class="key">喂食饲料</div>
                  <div class="value">{{ stageList[1].feedingName }}</div>
                </div>
                <div class="infoitem" v-if="stageList[1].vaccineName">
                  <div class="key">疫苗接种</div>
                  <div class="value">{{ stageList[1].vaccineName }}</div>
                </div>
                <div class="infoitem" v-if="stageList[1].pushTime">
                  <div class="key">入栏日期</div>
                  <div class="value">{{ stageList[1].formatpushTime }}</div>
                </div>
                <div class="infoitem" v-if="stageList[1].popTime">
                  <div class="key">出栏日期</div>
                  <div class="value">{{ stageList[1].formatpopTime }}</div>
                </div>
              </div>
              <div class="inforight">
                <img
                  v-if="stageList[1].thumbnailImageUrl"
                  style="width: 125px; height: 161px"
                  :src="stageList[1].thumbnailImageUrl"
                  alt=""
                />
                <img
                  v-if="!stageList[1].thumbnailImageUrl"
                  style="width: 125px; height: 161px"
                  src="https://asset.fsytss.com/trace/wcj/default_state_2.png"
                  alt=""
                />
                <div class="status" @click="showDialog(stageList[1].id)">
                  饲养情况
                </div>
              </div>
            </div>
          </div>

          <!-- 育肥阶段 -->
          <div class="traceInfoContain">
            <div
              style="
                margin-top: 12px;
                text-align: -webkit-center;
              "
            >
              <div style="margin-bottom: 4px" />
              <div class="homeProductTraceIcon" />
              <div
                style="
                  height: calc(100% - 5px - 6px);
                  width: 0;
                  border-left: #ad412f 3px dotted;
                  margin-top: 4px;
                  opacity: 0;
                "
               
              />
            </div>
            <div class="fromenterprise2">
              <div class="baseinfo">育肥阶段</div>
              <div class="infoleft">
                <div class="infoitem">
                  <div class="key">养殖天数</div>
                  <div class="value">{{ stageList[2].cycleDay }}</div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    养
                    <div style="width: 6.5px" />
                    殖
                    <div style="width: 7px" />
                    户
                  </div>
                  <div class="value">
                    {{
                      stageList[2].breedingFarmer
                        ? stageList[2].breedingFarmer
                        : "个体户"
                    }}
                  </div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    养
                    <div style="width: 6.5px" />
                    殖
                    <div style="width: 7px" />
                    场
                  </div>
                  <div class="value" style="color: #a50000">
                    {{
                      stageList[2].farmManagerName
                        ? stageList[2].farmManagerName
                        : "个体户"
                    }}
                  </div>
                </div>
                <div class="infoitem">
                  <div class="key">
                    圈
                    <div style="width: 6.5px" />
                    舍
                    <div style="width: 7px" />
                    码
                  </div>
                  <div class="value">
                    {{
                      stageList[2].farmManagerCircleHouse
                        ? stageList[2].farmManagerCircleHouse
                        : "个体户"
                    }}
                  </div>
                </div>

                <div class="infoitem" v-if="stageList[2].feedingName">
                  <div class="key">喂食饲料</div>
                  <div class="value">{{ stageList[2].feedingName }}</div>
                </div>
                <div class="infoitem" v-if="stageList[2].vaccineName">
                  <div class="key">疫苗接种</div>
                  <div class="value">{{ stageList[2].vaccineName }}</div>
                </div>
                <div class="infoitem" v-if="stageList[2].pushTime">
                  <div class="key">入栏日期</div>
                  <div class="value">{{ stageList[2].formatpushTime }}</div>
                </div>
                <div class="infoitem" v-if="stageList[2].popTime">
                  <div class="key">出栏日期</div>
                  <div class="value">{{ stageList[2].formatpopTime }}</div>
                </div>
              </div>
              <div class="inforight">
                <img
                  v-if="stageList[2].thumbnailImageUrl"
                  style="width: 125px; height: 161px"
                  :src="stageList[2].thumbnailImageUrl"
                  alt=""
                />
                <img
                  v-if="!stageList[2].thumbnailImageUrl"
                  style="width: 125px; height: 161px"
                  src="https://asset.fsytss.com/trace/wcj/default_state_3.png"
                  alt=""
                />
                <div class="status" @click="showDialog(stageList[2].id)">
                  饲养情况
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>

    <!-- 底部技术支持 -->
    <div class="bottom">一天膳事提供技术服务</div>

    <!-- <van-dialog v-model="show" confirm-button-text="关闭" confirm-button-color="#A1A1A1" >
      <div style="margin: 16px; font-weight: bold; text-align: center;">饲养记录</div>
      <div class="feedingItem">
        <div class="timedetailContainer">
            <div class="timedetail">饲养时间</div>
            <div>2024-05-13 18:00:35</div>
        </div>

      </div>
    </van-dialog> -->

    <van-dialog
      v-model="show"
      confirm-button-text="关闭"
      confirm-button-color="#A1A1A1"
    >
      <div style="margin: 16px; font-weight: bold; text-align: center">
        饲养记录
      </div>
      <div
        style="
          height: 50vh;
          overflow-y: scroll;
          padding: 0 8px;
          font-size: 14px;
        "
      >
        <van-list
          v-model="loading"
          :finished="true"
          v-if="feedingRecordList.length > 0"
        >
          <div
            style="display: flex; width: 100%"
            v-for="(item, index) in feedingRecordList"
            :key="index"
          >
            <div
              style="
                flex: 1;
                border-bottom: 0.5px solid rgb(229, 229, 229);
                padding: 8px;
                margin: 4px 0;
              "
            >
              <div style="display: flex; color: #a1a1a1">
                <div
                  class="bgc"
                  style="
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 13px;
                    color: #b54230;
                  "
                >
                  饲养时间
                </div>
                <div
                  style="
                    flex: 1;
                    color: #b54230;
                    font-weight: bold;
                    padding: 5px 0 5px 8px;
                    margin: auto 0;
                    text-align: right;
                  "
                >
                  {{ getFeedingTime(item.feedingTime) }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  color: #a1a1a1;
                  margin-top: 10px;
                  justify-content: space-between;
                "
              >
                <div style="width: 150px; padding-left: 8px">投喂饲料</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ item.feedingFood }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  color: #a1a1a1;
                  margin-top: 10px;
                "
              >
                <div style="padding-left: 8px">投喂频次</div>
                <div
                  style="
                    color: #222222;
                    margin-left: 8px;
                    font-size: 13px;
                    color: #231815;
                    line-height: 20px;
                  "
                >
                  {{ getFeedingFrequency(item.feedingFrequency) }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂未上传数据"
          style="height: 100%"
          v-if="feedingRecordList.length <= 0"
        />
      </div>
    </van-dialog>
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { Image as VanImage, Dialog, Empty, List, Loading } from "vant";
import { post } from "../../utils/http";
import moment from "moment";
Vue.use(VanImage).use(Dialog).use(Empty).use(List).use(Loading);
export default {
  name: "HomeProductTraceComponent",
  props: [
    "totalStageNum",
    "first",
    "last",
    "stageList",
    "supplierAuth1",
    "supplierAuth2",
    "supplierAuth3",
    "supplierName",
  ],
  data() {
    return {
      show: false,
      feedingRecordList: [],
      loadingAll: false,
      loading: false,
    };
  },
  methods: {
    showDialog(stageId) {
      this.loadingAll = true;
      const url =
        "/api/public/v2/manufacture/tracing/{stageId}/stageItemInfo".replace(
          "{stageId}",
          stageId
        );
      post(url, {}, false)
        .then((res) => {
          this.feedingRecordList = res.data.breedingBatchStageFeedingList;

          this.showFeedingRecord = true;
          this.show = true;
        })
        .catch((err) => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    onClickFirst() {
      this.$parent.onClickShowBusiness(this.first.trustList);
    },
    onClickFeedingRecord(item) {
      this.$parent.onClickShowFeedingRecord(item.id);
    },
    onClickVaccinumRecord(item) {
      this.$parent.onClickShowVaccinumRecord(item.id);
    },
    onClickFarm(item) {
      if (!item.farmManagerId) {
        return;
      }
      this.$parent.onClickShowFarm(item.farmManagerId);
    },
    getFeedingTime(feedTime) {
      return moment(feedTime).format("yyyy-MM-DD HH:mm:ss");
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getFeedingFrequency(feedingFrequency) {
      if (feedingFrequency) {
        switch (feedingFrequency) {
          case "EVERYDAY":
            return "每天一次";
          case "SINGLE_TIME":
            return "单次投喂";
          default:
            return "";
        }
      }
      return "";
    },
  },
  mounted() {
  },
};
</script>
  
  <style lang="less" scoped>
.enterprise {
  display: flex;
}
.enterpriseContainer {
  // display: flex;
  width: 100%;
}
.left {
  width: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.traceInfoContain{
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
}
.code {
  width: 100%;
}
.line {
  width: 5px;
}
.fromenterprise {
  flex: 1;
  margin-top: 27px;
  margin-left: 12px;
  // margin-left: 13px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding-top: 29px;
  padding-bottom: 12px;
}
.baseinfo {
  position: absolute;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -11px;
}
.name {
  padding: 0 16px;
  position: relative;
  font-weight: 700;
  font-size: 21px;
  color: #231815;
  line-height: 20px;
  text-align: center;
}
.fromenterprise2 {
  flex: 1;
  margin-top: 27px;
  margin-left: 12px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding-top: 29px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.infoleft {
  width: 155px;
  padding-left: 10px;
  padding-right: 10px;
}
.inforight {
  // width: 125px;
  margin-top: -29px;
  border-top-right-radius: 8px;
  overflow: hidden;
}
.infoitem {
  display: flex;
  justify-content: space-between;
}
.key {
  font-size: 12px;
  color: #666666;
  line-height: 20px;
  width: 64px;
  display: flex;
}
.value {
  font-size: 12px;
  color: #231815;
  line-height: 20px;
  width: 94px;
}
.status {
  display: block;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 16px;
  width: 95px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 13px;
  line-height: 14px;
  color: #fff;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}

.bgc {
  background: url("../../assets/images/project1/feedingTimeBack.png") no-repeat
    center / cover;
  background-size: 100% 100%; /* 拉伸背景图片以填充整个盒子 */
  padding: 0 8px;
}
.inforight {
  img {
    border-bottom-left-radius: 8px;
  }
}
.businessTagContainer {
  // text-align: center;

  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  .businessTag {
    display: flex;
    justify-content: center;
    margin-right: 6px;
  }
}
.homeProductTraceIcon {
  height: 15px;
  width: 15px;
  background: url(../../assets/images/project1/code.png) no-repeat center /
    cover;
  margin-top: 10px;
}
</style>